const styles = {
  topBarDetails: {
    boxShadow: 3,
    backgroundColor: 'white',
    p: 1
  },

  linkDetails: (color) => ({
    cursor: 'pointer',
    fontWeight: 'bold',
    color,
    fontSize: '1rem'
  }),

  buttonDetails: {
    // my: 5,
    // ml: '50%',
    boxShadow: 0,
    fontWeight: 'bold',
    textTransform: 'unset',
    // position: 'relative',
    mr: 2,
    '@media (max-width:600px)': {
      fontSize: '1rem', // Adjust font size for smaller screens
      width: '8rem', // Adjust width for smaller screens
    },
  }
}

export default styles;