import { Box, Card, Typography, useMediaQuery, CardContent, CardMedia } from "@mui/material";

const Partners = () => {
  const PartnerLogo = ({ image }) => (
    <Card
      sx={{
        p: 1,
        width: {
          xs: 100,
          sm: 100,
          md: 110,
          lg: 150,
          xl: 150,
        },
        height: {
          xs: 100,
          sm: 100,
          md: 110,
          lg: 150,
          xl: 150,
        },
        bgcolor: "white",
        borderRadius: 15,
        border: "solid 2px #F2F2F2",
        padding: "1rem 1rem 0 1rem", objectFit: "contain",
        
      }}
    >
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            image={image}
           
          />
        </div>
      </CardContent>
    </Card>
  );

  return (
    <Box
      id="partners"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mt: 8,
      }}
    >
      <Typography color="primary" variant="h4" sx={{ fontWeight: "bold" }}>
        Parceiros
      </Typography>
      <Typography
        variant="body"
        sx={{
          mt: 2,
          mx: 4,
          textAlign: "center",
        }}
      >
        O câncer não conhece fronteiras, a prevenção e o apoio podem fazer toda
        a diferença.
        <br />
        Ao se tornar um parceiro do Caminho Rosa e Caminho Azul, você não está
        apenas associando sua empresa a uma causa nobre, mas também demonstrando
        seu compromisso com a comunidade e a saúde.
      </Typography>
      <Box
        sx={{
          mt: 4,
          py: 2,
          width: "100%",
          display: "flex",
          bgcolor: "white",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center'
        }}
      >
        {
          useMediaQuery('(max-width: 767px)')
          ? (
            <>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <PartnerLogo image="/assets/partners/emprofac.png" />
                <PartnerLogo image="/assets/partners/chuvaLogo.png" />
                <PartnerLogo image="/assets/partners/PingLogo.png" />
                <PartnerLogo image="/assets/partners/inpsLogo.png" />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mt: 2 }}>
                <PartnerLogo image="/assets/partners/clinaliseLogo.jpeg" />
                <PartnerLogo image="/assets/partners/electraLogo.jpeg" />
                <PartnerLogo image="/assets/partners/enaporLogo.jpeg" />
                <PartnerLogo image="/assets/partners/garantiaSaude.jpeg" />
              </Box>
            </>
          ) : (
            <Box sx={{
              mt: 4,
              py: 2,
              width: "100%",
              display: "flex",
              bgcolor: "white",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
              <PartnerLogo image="/assets/partners/clinaliseLogo.jpeg" />
              <PartnerLogo image="/assets/partners/electraLogo.jpeg" />
              <PartnerLogo image="/assets/partners/emprofac.png" />
              <PartnerLogo image="/assets/partners/chuvaLogo.png" />
              <PartnerLogo image="/assets/partners/PingLogo.png" />
              <PartnerLogo image="/assets/partners/enaporLogo.jpeg" />
              <PartnerLogo image="/assets/partners/garantiaSaude.jpeg" />
              <PartnerLogo image="/assets/partners/inpsLogo.png" />
            </Box>
          )
        }
      </Box>
      <Box
        sx={{ mt: 4, alignSelf: "center", textAlign: "center", maxWidth: 500 }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Benefícios para Parceiros
        </Typography>
        <Typography sx={{ mt: 1, mx: 4 }}>
          Benefícios que as empresas parceiras recebem, incluindo descontos para
          colaboradores e vouchers para clientes.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", xl: "row" },
          justifyContent: { xs: "center", xl: "space-around" },
          mt: 4,
          width: { xl: "70%" },
        }}
      >
        <Box
          sx={{
            width: {
              xs: 300,
              sm: 400,
              md: 500,
              lg: 450,
              xl: 450,
            },
            mt: { xs: 6, xl: 0 },
          }}
          component="img"
          src="/assets/beneficiosRoza.jpeg"
        />
        <Box
          sx={{
            width: {
              xs: 300,
              sm: 400,
              md: 500,
              lg: 450,
              xl: 450,
            },
          }}
          component="img"
          src="/assets/beneficiosAzul.jpeg"
        />
      </Box>
    </Box>
  );
};

export default Partners;
