import { CssBaseline, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TopBar from './scenes/global/TopBar';
import Home from './scenes/Home';
import AboutUs from './scenes/AboutUs';
import Partners from './scenes/Partners';
import { theme } from './theme';
import HowToHelp from './scenes/HowToHelp';
import EventsAndActivities from './scenes/EventsAndActivities';
import Footer from './scenes/Footer';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '300vh', // Corrected typo
    width: '100%',
  },
  test: {
    background: `linear-gradient(90deg, ${theme.palette.secondary.main} -109%, rgba(255, 255, 255, 0.00) 30%)`,
    minHeight: '129vh', // Corrected typo
  },
  test2: {
    background: `linear-gradient(270deg, ${theme.palette.primary.main} -70%, rgba(110, 193, 228, 0.00) 30%)`,
    minHeight: '129vh',
    alignContent: 'center',
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <Home />
      <div className={classes.root}>
        <div className={classes.test}>
          <div className={classes.test2}>
            <AboutUs />
            <Partners />
            <HowToHelp />
            <EventsAndActivities />
          </div>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
