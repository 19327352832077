import { Box, Typography, useMediaQuery } from "@mui/material";

const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width: 1000px)");

  return (
    <Box
      id="about-us"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        px: 4,
        mt: 4,
      }}
    >
      <Typography color="secondary" variant="h5" sx={{ fontWeight: "bold" }}>
        Sobre Nós
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 6.25, fontWeight: "bold", textAlign: "center" }}
      >
        Caminho Rosa e Caminho Azul para a Conscientização
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row-reverse",
          justifyContent: isMobile ? "center" : "space-around",
          mt: 5,
          width: useMediaQuery("(max-width: 1450px)") ? "100%" : "70%",
        }}
      >
        <Box
          component="img"
          src="/assets/caminhoParaConcientizacao.svg"
          sx={{
            alignSelf: "center",
            width: {
              xs: 200,
              sm: 300,
              md: 250,
              xl: 300,
            },
          }}
        />
        <Box
          sx={{
            maxWidth: 500,
            textAlign: "center",
            mt: isMobile ? "3rem" : "0rem",
            alignSelf: "center",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Visão
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Promover a conscientização sobre o câncer de mama (Caminho Rosa) e
              a saúde masculina (Caminho Azul) em nossa comunidade, oferecendo
              eventos inclusivos, informativos e interativos durante os meses de
              outubro e novembro.
            </Typography>
          </Box>
          <Box sx={{ maxWidth: 500 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 5 }}>
              Nossa Causa
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              O caminho rosa e azul é uma iniciativa que visa sensibilizar a
              comunidade sobre duas questões cruciais de saúde:
              <br />
              o câncer de mama e a saúde masculina. <br />O objetivo é criar um
              caminho encantador decorado com banners e bandeirolas de várias
              instituições públicas e privadas, pretendemos incluir todos
              aqueles que queiram participar e apoiar este evento tão importante
              para a sociedade.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
