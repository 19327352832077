import Lottie from 'react-lottie';
import animationData from '../../lottiesJson/worlAgainstCancer.json';

const WorldAgainstCancerLottie = ({ width }) => (
  <Lottie
    width={width}
    options={{
      loop: true,
      autoplay: true,
      animationData
    }}
  />
);

export default WorldAgainstCancerLottie;
