import axios from "axios";
import { Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import PingIcon from "../../components/icons/Ping";
import PaymentForm from "../../components/PaymentForm";
import LoadingLottie from "../../components/lotties/loading";
import { theme } from "../../theme";
import CircularProgress from '@mui/material/CircularProgress';

import formatAmount from "../../utils/formatAmount";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "70vh", // Corrected typo
    backgroundImage: 'url("/assets/hospital.jpeg")', // Removed extra curly brace
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  test: {
    background: `linear-gradient(196deg, ${theme.palette.secondary.main} -59.27%, rgba(255, 255, 255, 0.00) 30%)`,
    minHeight: "70vh", // Corrected typo
  },
  test2: {
    background: `linear-gradient(0deg, ${theme.palette.primary.main} -123.24%, rgba(110, 193, 228, 0.00) 50.86%)`,
    minHeight: "70vh",
    padding: 10,
    alignContent: "center",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/info`
        );
        setTotal(response.data.total);
      } catch (error) {
        setError(true);
      }
      setError(false);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleOpenPaymentForm = () => {
    setIsPaymentFormOpen(true);
  };

  const handleClosePaymentForm = () => {
    setIsPaymentFormOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.test}>
        <div className={classes.test2}>
          <Box
            sx={{
              display: "flex",
              mt: { xs: 4, md: 6 },
              flexDirection: "column",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", textAlign: "center" }}
              variant="h1"
            >
              Luta contra Cancro de
            </Typography>
            <Box sx={{ display: "flex", flexDirection: 'column' }}>
              <Typography
                variant="h1"
                color="secondary"
                sx={{ fontWeight: "bold", textAlign: 'center' }}
              >
                Mama
              </Typography>
              <LoadingLottie width={120} />
              <Typography
                variant="h1"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                Próstata
              </Typography>
            </Box>
            <Typography
              variant="h5"
              sx={{ mt: 2, fontWeight: "bold", color: theme.palette.grey.main }}
            >
              {isLoading ? (
                <CircularProgress sx={{ fontSize: 80 }} />
              ) : (
                "Total de doações feitas"
              )}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 1 }}>
              {total && formatAmount(total)}
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 6,
                width: {
                  xs: "16rem",
                  sm: "25rem",
                },
                fontWeight: "bold",
                textTransform: "unset",
              }}
              href="#how-to-help"
              endIcon={<PingIcon />}
            >
              Doe com Ping
            </Button>
            <PaymentForm
              isOpen={isPaymentFormOpen}
              handleClose={handleClosePaymentForm}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Home;
