export const countryCodes = [
  {
    flag: '&#127465;&#127466;',
    code: '+49'
  },
  {
    flag: '&#127462;&#127476;',
    code: '+244'
  },
  {
    flag: '&#127462;&#127479;',
    code: '+54'
  },
  {
    flag: '&#127463;&#127476;',
    code: '+591'
  },
  {
    flag: '&#127463;&#127479;',
    code: '+55'
  },
  {
    flag: '&#127463;&#127466;',
    code: '+32'
  },
  {
    flag: '&#127464;&#127483;',
    code: '+238'
  },
  {
    flag: '&#127464;&#127462;',
    code: '+1'
  },
  {
    flag: '&#127482;&#127480;',
    code: '+1'
  },
  {
    flag: '&#127462;&#127466;',
    code: '+971'
  },
  {
    flag: '&#127466;&#127480;',
    code: '+34'
  },
  {
    flag: '&#127467;&#127479;',
    code: '+33'
  },
  {
    flag: '&#127468;&#127475;',
    code: '+224'
  },
  {
    flag: '	&#127475;&#127473;',
    code: '+31'
  },
  {
    flag: '&#127470;&#127466;',
    code: '+353'
  },
  {
    flag: '&#127470;&#127481;',
    code: '+39'
  },
  {
    flag: '&#127473;&#127482;',
    code: '+352'
  },
  {
    flag: '&#127475;&#127476;',
    code: '+47'
  },
  {
    flag: '&#127477;&#127481;',
    code: '+351'
  },
  {
    flag: '&#127472;&#127466;',
    code: '+254'
  },
  {
    flag: '&#127468;&#127463;',
    code: '+44'
  },
  {
    flag: '&#127479;&#127484;',
    code: '+250'
  },
  {
    flag: '&#127480;&#127475;',
    code: '+221'
  },
  {
    flag: '&#127464;&#127469;',
    code: '+41'
  },
  {
    flag: '&#127480;&#127466;',
    code: '+46'
  }
];