import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";

const EventsAndActivities = () => {
  const EventObjective = ({ title, description }) => (
    <Card
      sx={{
        mt: 3,
        borderRadius: 2,
        width: { xs: 230, lg: 200 },
        boxShadow: "0px 2px 10px 1px rgba(80, 80, 80, 0.10)",
      }}
    >
      <CardContent sx={{ height: "auto" }}>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        p: 1,
        my: 8,
        px: 4,
      }}
    >
      <Typography color="primary" variant="h4" sx={{ fontWeight: "bold" }}>
        Eventos e Atividades
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, fontWeight: "bold", textAlign: "center" }}
      >
        Caminho Rosa e Caminho Azul para a Conscientização
      </Typography>
      <Box
        sx={{
          mt: 4,
          textAlign: "center",
          display: "flex",
          flexDirection: {
            xs: "column",
          },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src="/assets/caminhoCampanhaRoza.svg"
            sx={{
              width: {
                xs: 200,
                sm: 250,
                md: 250,
              },
            }}
          />
          <Box
            component="img"
            src="/assets/caminhoCampanhaAzul.svg"
            sx={{
              mt: {
                xs: 6,
                md: 0,
              },
              width: {
                xs: 200,
                sm: 250,
                md: 250,
              },
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            width: {
              md: 800,
            },
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: {
              xs: "center",
              sm: "space-around",
            },
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Visão
            </Typography>
            <Typography
              sx={{
                maxWidth: {
                  xs: 500,
                  sm: 300,
                },
                mt: 2,
              }}
            >
              Criar um evento inclusivo e inspirador que promova a
              conscientização sobre o câncer de mama, apoie sobreviventes,
              homenageie aqueles que lutaram e lutam contra a doença. O Evento
              Caminho Rosa será uma celebração de vida e apoio.
            </Typography>
          </Box>
          <Box
            sx={{
              mt: {
                xs: 4,
                md: 0,
              },
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Nossa Causa
            </Typography>
            <Typography
              sx={{
                maxWidth: {
                  xs: 500,
                  sm: 300,
                },
                mt: 2,
              }}
            >
              Criar um evento inclusivo e inspirador que promova a
              conscientização sobre a saúde masculina, com foco na prevenção e
              diagnóstico precoce do câncer de próstata, apoiar homens que
              enfrentam desafios de saúde, promova o bem-estar masculino e
              angariar fundos para apoiar tratamentos.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ mt: 6, width: { sm: "80%", md: "60%", lg: "80%", xl: "60%" } }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mb: 4, textAlign: "center" }}
        >
          Objectivo dos dois eventos
        </Typography>
        <Box sx={{ alignSelf: "center" }}>
          {useMediaQuery(`(min-width: 1200px)`) ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <EventObjective
                title="Fundo solidário"
                description="
                        Temos o objetivo de lançar uma iniciativa para
                        apoiar pacietes carnentes que necessitam de exames de biópsia, 
                        essenciais para o diagnóstico e tratamento do câncer e outras condições médicas.
                      "
              />
              <EventObjective
                title="Transparência"
                description="Compromisso com total transparência na captação de recursos,
                        atualizando em tempo real o montante arrecadado.
                      "
              />
              <EventObjective
                title="Doações"
                description="A campanha de doações está aberta a nível nacional e internacional,
                        convidando contribuições de todas as partes do mundo,
                        independentemente do valor.
                      "
              />
              <EventObjective
                title="Para a Comunidade"
                description="Além das doações, promovemos sorteios nos eventos
                        para envolver a comunidade na causa.
                      "
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    sm: "space-around",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  alignSelf: "center",
                }}
              >
                <EventObjective
                  title="Fundo solidário"
                  description="
                        Temos o objetivo de lançar uma iniciativa para
                        apoiar pacietes carnentes que necessitam de exames de biópsia, 
                        essenciais para o diagnóstico e tratamento do câncer e outras condições médicas.
                      "
                />
                <EventObjective
                  title="Transparência"
                  description="Compromisso com total transparência na captação de recursos,
                        atualizando em tempo real o montante arrecadado.
                      "
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  alignSelf: "center",
                }}
              >
                <EventObjective
                  title="Doações"
                  description="A campanha de doações está aberta a nível nacional e internacional,
                        convidando contribuições de todas as partes do mundo,
                        independentemente do valor.
                      "
                />
                <EventObjective
                  title="Para a Comunidade"
                  description="Além das doações, promovemos sorteios nos eventos
                        para envolver a comunidade na causa.
                      "
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EventsAndActivities;
