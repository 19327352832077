import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#4295C1',
    },
    secondary: {
      main: '#E445AA',
    },
    gray: {
      main: '#34495E',
    },
    grey: {
      main: '#97A4B1',
    },
  },
  typography: {
    fontFamily: 'Ubuntu',
    body1: {
      color: '#34495E',
      '@media (max-width:720px)': {
        fontSize: '1rem', // Adjust font size for smaller screens
      },
      '@media (min-width:720px)': {
        fontSize: '1rem', // Adjust font size for smaller screens
      },
    },
    body2: {
      color: '#97A4B1',
      '@media (max-width:720px)': {
        fontSize: '0.8rem', // Adjust font size for smaller screens
      },
      '@media (min-width:720px)': {
        fontSize: '0.8rem', // Adjust font size for smaller screens
      },
    },
    h1: {
      color: '#34495E',
      fontWeight: 'bold',
      '@media (max-width:900px)': {
        fontSize: '2.2rem', // Adjust font size for smaller screens
      },
      '@media (min-width:900px)': {
        fontSize: '3rem', // Adjust font size for smaller screens
      }
    },
    h3: {
      color: '#34495E',
      fontWeight: 'bold',
      '@media (max-width:900px)': {
        fontSize: '1.6rem', // Adjust font size for smaller screens
      },
      '@media (min-width:900px)': {
        fontSize: '2.5rem', // Adjust font size for smaller screens
      },
    },
    h4: {
      color: '#34495E',
      fontWeight: 'bold',
      '@media (max-width:900px)': {
        fontSize: '1.2rem', // Adjust font size for smaller screens
      },
      '@media (min-width:900px)': {
        fontSize: '2rem', // Adjust font size for smaller screens
      },
    },
    h5: {
      color: '#34495E',
      fontWeight: 'bold',
      '@media (max-width:900px)': {
        fontSize: '1rem', // Adjust font size for smaller screens
      },
      '@media (min-width:900px)': {
        fontSize: '1.5rem', // Adjust font size for smaller screens
      },
    }
  },
  shape: {
    borderRadius: 10
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: 16, // Default font size
          // width: 100, // Default width
          '@media (max-width:600px)': {
            fontSize: '1rem', // Adjust font size for smaller screens
            // width: '20rem', // Adjust width for smaller screens
          },
        },
      },
    }
  },
});
