const invertStr = (str) => {
  let newStr = '';
  for (let i = str.length - 1; i >= 0; i--) {
    newStr += str[i];
  }
  return newStr;
}

const formatAmount = (total) => {
  let amount = invertStr(String(total));
  let formated = 'EVC ';
  while(amount) {
    formated += amount.slice(0, 3) + (amount.slice(3) ? ' ' : '');
    amount = amount.slice(3);
  }
  
  return invertStr(formated);
};

export default formatAmount;

