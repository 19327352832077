import { Facebook, Instagram } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "rgba(0, 113, 188, 0.66)",
        p: 4,
        fontWeight: "bold",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <img src="/assets/urgimedLogo.svg" />
        <Typography sx={{ mt: 2, color: "white" }}>
          <u>info@urgimed.cv</u>
          &nbsp;&nbsp;| +238 599 78 63 | +238 986 81 47
        </Typography>
        <Box
          component="img"
          src="/assets/poweredByChuva.png"
          sx={{ width: 150, cursor: 'pointer' }}
          onClick={() => window.open('https://chuva.io/', '_blank')}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "white",
        }}
      >
        <Facebook
          sx={{ mr: 2, fontSize: 30, cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.facebook.com/UrgimedLda", "_blank")
          }
        />
        <Instagram
          sx={{ fontSize: 30, cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.instagram.com/urgimed.mindelo/", "_blank")
          }
        />
      </Box>
      <Typography sx={{ mt: 2, color: "white" }}>
        &copy; 2023, All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
