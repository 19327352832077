import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import parse from 'html-react-parser';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import {
  ErrorOutline as ErrorIcon,
  PhoneOutlined as PhoneIcon,
} from '@mui/icons-material';

import PingIcon from '../icons/Ping';
import LoadingLottie from '../lotties/loading';
import SuccessLottie from '../lotties/Success';

import { countryCodes } from '../../utils/constants';
import { theme } from '../../theme';

const PaymentForm = ({ isOpen, handleClose }) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState('+238');

  const handleFormSubmit = async (values) => {
    handleClose(values);
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/donate`, {
        phone_number: `${countryCode}${values.phoneNumber}`,
        amount: values.amount,
      });
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
    setIsLoading(false);
  };

  const handleCloseSuccessPopUp = () => {
    setSuccess(false);
    setError(false);
  };

  const matches = useMediaQuery('(max-width:600px)');

  const initialValues = {
    phoneNumber: '',
    amount: '',
    countryCode: '',
  };

  const donerSchema = yup.object().shape({
    phoneNumber: yup.string().required('Obrigatório'),
    amount: yup
      .number()
      .min(100, 'O valor minimo de transacão no Ping é de 100CVE')
      .required('Obrigatório'),
  });

  const handleChangeCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        onClose={() => {
          handleClose();
          setCountryCode("+238");
        }}
      >
        <Box sx={{ pt: 4, textAlign: "center", mb: 0 }}>
          <Box component="img" src="assets/PingLogo.svg" sx={{ height: 40 }} />
        </Box>
        <DialogContent sx={{ px: 4, pb: 4 }}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={donerSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex' }}>
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ flex: 1.3, mr: 1 }}
                      name="countryCode"
                    >
                      <InputLabel id="demo-simple-select-label">
                        {matches ? 'Cód. do país' : 'Código do país'}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="CountryCode"
                        name="countryCode"
                        onChange={(e) => {
                          handleChangeCountryCode(e);
                          handleChange(e);
                        }}
                        value={countryCode}
                        onBlur={handleBlur}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        {countryCodes.map((country) => (
                          <MenuItem value={country.code}>
                            {parse(country.flag) + " " + country.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ flex: 2 }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="Number"
                      label="Número de telefone"
                      startAdornment={<PhoneIcon />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon
                              sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.grey.main,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      name="phoneNumber"
                      error={!!touched.phoneNumber && !!errors.phoneNumber}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Box>
                  <TextField
                    sx={{ mt: 2 }}
                    variant="outlined"
                    size="small"
                    type="Number"
                    label="Valor da doaçāo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" size="small">
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 'bold' }}
                          >
                            CVE
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    sx={{
                      mt: 2,
                      width: '100%',
                      fontWeight: 'bold',
                      textTransform: 'unset',
                      backgroundColor: theme.palette.primary.main,
                      '@media (max-width:600px)': {
                        fontSize: '1rem', // Adjust font size for smaller screens
                        width: '100%', // Adjust width for smaller screens
                      },
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                    endIcon={<PingIcon />}
                  >
                    Fazer doaçāo
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={isLoading || success || error}
        onClose={handleCloseSuccessPopUp}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {success ? (
            <SuccessLottie width={150} />
          ) : (
            error && (
              <ErrorIcon size="large" sx={{ color: 'red', fontSize: 50 }} />
            )
          )}
        </DialogTitle>
        <DialogContent sx={{ px: 4, pb: 4 }}>
          {(isLoading && <LoadingLottie width={150} />) || (
            <Typography
              variant="body2"
              sx={{
                fontSize: '1rem',
                maxWidth: 250,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {success
                ? `
                  Verifique a sua conta Ping ou caixa de mensagem para finalizar a doação.
                  Obrigada por contribuir para esta causa e tornar esta assistência acessível aos que dela necessitam.
                `
                : error && 'Algo deu errado tente novamente mais tarde.'}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentForm;
