import { useState } from 'react';
import { Box, Button, Link, AppBar, Toolbar, Menu } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './styles';
import { theme } from '../../../theme';
import PingIcon from '../../../components/icons/Ping';


const TopBar = () => {
  const isMobile = useMediaQuery("(max-width:720px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <AppBar position="sticky" sx={styles.topBarDetails}>
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            justifyContent: isMobile ? "start" : "center",
          }}
        >
          <Box sx={styles.sectionContainerDetails}>
            {isMobile ? (
              <Box sx={{ width: "1rem" }}>
                <Button
                  id="basic-button"
                  aria-controls={isMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? "true" : undefined}
                  size="lg"
                  onClick={handleMenuOpen}
                >
                  <MenuIcon sx={{ color: theme.palette.grey.main }} />
                </Button>
                <Menu
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Link
                      underline="none"
                      href="#about-us"
                      sx={{ ...styles.linkDetails(theme.palette.grey.main) }}
                      onClick={handleMenuClose}
                    >
                      Sobre Nós
                    </Link>
                    <Link
                      href="#partners"
                      underline="none"
                      color="grey"
                      sx={{
                        ...styles.linkDetails(theme.palette.grey.main),
                        mt: 2,
                      }}
                      onClick={handleMenuClose}
                    >
                      Parceiros
                    </Link>
                    <Link
                      href="#how-to-help"
                      underline="none"
                      color="grey"
                      sx={{
                        ...styles.linkDetails(theme.palette.grey.main),
                        mt: 2,
                      }}
                      onClick={handleMenuClose}
                    >
                      Como Doar
                    </Link>
                  </Box>
                </Menu>
              </Box>
            ) : (
              <>
                <Link
                  underline="none"
                  href="#about-us"
                  sx={{ ...styles.linkDetails(theme.palette.grey.main), mr: 2 }}
                >
                  Sobre Nós
                </Link>
                <Link
                  href="#partners"
                  underline="none"
                  color="grey"
                  sx={{ ...styles.linkDetails(theme.palette.grey.main), mr: 2 }}
                >
                  Parceiros
                </Link>
                <Link
                  href="#how-to-help"
                  underline="none"
                  color="grey"
                  sx={styles.linkDetails(theme.palette.grey.main)}
                >
                  Como Doar
                </Link>
              </>
            )}
          </Box>
          <Button
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              textTransform: "unset",
              width: {
                sm: "10rem",
              },
              "@media (max-width:600px)": {
                fontSize: "1rem",
                width: "13rem",
              },
              right: "2rem",
              position: "absolute",
            }}
            href="#how-to-help"
            variant="contained"
            color="primary"
            size="small"
            endIcon={<PingIcon />}
            >
              Doe com Ping
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
