import { Box, Card, CardContent, CardMedia, Button, Typography, useMediaQuery, Link } from "@mui/material";
import WorldAgainstCancerLottie from "../../components/lotties/WorldAgianstCancer";
import PaymentForm from "../../components/PaymentForm";
import axios from "axios";
import { useState, useEffect } from "react";
import PingIcon from "../../components/icons/Ping";

const HowToHelp = () => {
  const PaymentStepComponent = ({ title, description, image, fitImage, onClick }) => (
    <Card
      sx={{
        maxWidth: {
          xs: 200,
          sm: 250,
          md: 200,
          lg: 250,
        },
        maxHeight: {
          xs: 220,
          sm: 260,
          md: 220,
          lg: 260,
        },
        mt: 4,
        borderRadius: 2,
        boxShadow: "0px 2px 10px 1px rgba(80, 80, 80, 0.10)",
      }}
      onClick={onClick}
    >
      <CardMedia
        component="img"
        sx={{
          height: {
            xs: 120,
            sm: 160,
            md: 120,
            lg: 150,
          },
          ...(!fitImage
            ? { padding: "1rem 1rem 0 1rem", objectFit: "contain" }
            : null),
        }}
        image={image}
        title={title}
      />
      <CardContent sx={{ px: 2 }}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }}>{title}</Typography>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );

  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/info`
        );
        setTotal(response.data.total);
      } catch (error) {
        setError(true);
      }
      setError(false);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleOpenPaymentForm = () => {
    setIsPaymentFormOpen(true);
  };

  const handleClosePaymentForm = () => {
    setIsPaymentFormOpen(false);
  };

  return (
    <Box
      id="how-to-help"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        mt: 8,
      }}
    >
      <Typography
        color="secondary"
        variant="h4"
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Como Você Pode Ajudar?
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }}>
        Saúde aliada à tecnologia!
      </Typography>
      <Typography variant="body" sx={{ mt: 2, textAlign: "center" }}>
        Urgimed e Ping se uniram para permitir que a nossa diáspora consiga
        contribuir facilmente para esta causa de qualquer parte do mundo. 
      </Typography>
      <WorldAgainstCancerLottie width={useMediaQuery('(max-width: 767px)') ?  300 : 600}/>
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Para fazer a sua contribuição siga os seguintes passos:
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <ol sx={{ textAlign: 'center' }}>
          <li>
            <Link variant="body1" onClick={() => window.open('https://ping.chuva.io', '_blank')}>
              Faça download de Ping.
            </Link>
          </li>
          <li>
            <Typography variant="body1">
              Crie a sua conta. Podes seguir os passos deste
              {' '}
              <Link variant="body1" onClick={() => window.open("https://www.youtube.com/watch?v=-7zlR0B9bQk", '_blank')}>
                tutorial
              </Link>.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Clique em "Doe agora com Ping".
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Insira o seu número de telemóvel e o valor que quer contribuir.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              E confirme o pagamento no seu aplicativo Ping.
            </Typography>
          </li>
        </ol>
      </Box>
      <Button
        variant="contained"
        sx={{
          mt: 6,
          width: {
            xs: "16rem",
            sm: "25rem",
          },
          fontWeight: "bold",
          textTransform: "unset",
        }}
        onClick={handleOpenPaymentForm}
        endIcon={<PingIcon />}
      >
        Doe agora com Ping
      </Button>
      <PaymentForm
        isOpen={isPaymentFormOpen}
        handleClose={handleClosePaymentForm}
      />
    </Box>
  );
};

export default HowToHelp;
