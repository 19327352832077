import Lottie from 'react-lottie';
import animationData from '../../lottiesJson/SuccessAnimation.json';

const SuccessLottie = ({ width }) => (
  <Lottie
    width={width}
    options={{
      loop: true,
      autoplay: true,
      animationData
    }}
  />
);

export default SuccessLottie;
